import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';

const TitleIndustry = ({ data, className, spacer, wide }) => {
  return (
    <Section
      className={cn('title-industry', spacer && 'title-industry_spacer', [
        className,
      ])}
    >
      <Container>
        <Row>
          <Column
            className={`offset-lg-2 col-lgplus-${wide ? '7' : '6'}`}
            lg="9"
          >
            {data.content && <Text className="body-lg">{data.content}</Text>}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

TitleIndustry.defaultProps = {
  className: '',
  wide: false,
};

TitleIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  spacer: PropTypes.bool,
  wide: PropTypes.bool,
};

export default TitleIndustry;
